import isEmpty from 'lodash.isempty';
import {
  GigyaResponse,
  GigyaScreensetPayload,
  GigyaAdditionalEventsHandlers,
} from '@/shared/types';

export const useGigyaScreensetLoader = (
  screensetPayload: GigyaScreensetPayload,
  screenEvents?: GigyaAdditionalEventsHandlers
): GigyaResponse => {
  if (window?.gigya?.accounts?.showScreenSet) {
    window.gigya.accounts.showScreenSet({
      ...screensetPayload,
    });

    if (!isEmpty(screenEvents)) {
      window.gigya.accounts.addEventHandlers({
        ...screenEvents,
      });
    }

    return { status: 'OK', errorCode: 0 };
  } else {
    return { status: 'FAIL', errorCode: 400 };
  }
};
