export const useLoginRegisterProxyUrl = (siteServicesUrl: string): string => {
  const proxyPath = `${siteServicesUrl}/login/proxy`;

  if (window?.gigya?.utils?.URL?.addParamsToURL) {
    const proxyUrl = window.gigya.utils.URL.addParamsToURL(proxyPath, {
      mode: 'afterLogin',
    });

    return proxyUrl;
  }

  return proxyPath;
};
