
import { getCurrentInstance } from 'vue';
import {
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';
import { useLoginRegisterProxyUrl } from '@/composables/gigya/useLoginRegistration';
import FormRequiredFieldInfo from '@/components/form/FormRequiredFieldInfo.vue';
import { useGigyaScreensetLoader } from '@/composables/gigya/useGigyaScreensetLoader';
import MainContent from '@/components/main-content/MainContent.vue';
import Loader from '@/components/loader/Loader.vue';
import { focusOnFirstElement, includeGigya } from '@/shared/utils';
import { GigyaResponse, GigyaCommonErrorCodesEnum } from '@/shared/types';
import UnableToLoadScreensetMessage from '@/components/messages/UnableToLoadScreensetMessage.vue';

export default defineComponent({
  components: {
    SectionContentContainer,
    MainContent,
    FormRequiredFieldInfo,
    Loader,
    UnableToLoadScreensetMessage,
  },
  setup: () => {
    const instance = getCurrentInstance();
    const { $config } = instance!.proxy;

    const isScreenPending = ref<boolean>(true);
    const isTechnicalError = ref<boolean>(false);
    const isForbiddenError = ref<boolean>(false);
    const hasScreenLoadFailed = ref<boolean>(false);
    const isGigyaScriptLoaded = ref<boolean>(false);

    const afterLoadHandler = () => {
      isScreenPending.value = false;
    };

    const onFormRequestErrorHandler = (event: GigyaResponse) => {
      if (event.errorCode === GigyaCommonErrorCodesEnum.FORBIDDEN) {
        isForbiddenError.value = true;
      } else {
        isTechnicalError.value = true;
      }
    };

    const focusOnInvalidFieldHandler = () =>
      focusOnFirstElement(['.gigya-error']);

    const clearErrorState = () => {
      isTechnicalError.value = false;
      isForbiddenError.value = false;
    };

    watch(isGigyaScriptLoaded, () => {
      const hostWithoutCaoMarketing = $config.SITE_SERVICES_URL;
      const proxyRedirect = useLoginRegisterProxyUrl(hostWithoutCaoMarketing);
      const { status } = useGigyaScreensetLoader(
        {
          screenSet: 'CAO-RegistrationLoginAdvanced',
          containerID: 'login-register-screen',
          onAfterScreenLoad: afterLoadHandler,
          onAfterValidation: focusOnInvalidFieldHandler,
          onError: onFormRequestErrorHandler,
          onSubmit: clearErrorState,
        },
        {
          onLogin: () => {
            window.location.href = proxyRedirect;
          },
        }
      );

      if (status === 'FAIL') {
        isScreenPending.value = false;
        hasScreenLoadFailed.value = true;
      }
    });

    onMounted(() => {
      includeGigya(
        `https://cdns.gigya.com/JS/gigya.js?apiKey=${$config.GIGYA_KEY}`,
        'gigya-placeholder',
        () => {
          isGigyaScriptLoaded.value = true;
        }
      );
    });

    return {
      isScreenPending,
      isTechnicalError,
      hasScreenLoadFailed,
      isForbiddenError,
    };
  },
  head: {
    title: 'Login',
    meta: [
      {
        hid: 'og:title',
        property: 'og:title',
        content: `Login | Cambridge Advance Online`,
      },
    ],
  },
});
